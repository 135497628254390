<template>
  <QuestionBase :question="question" :errors="[...errorsPost, ...serverErrors, ...jsErrors]" :valid="!!data" >
    <InputRadio
        v-model="data"
        :options="titleOptions"
        :db="db"
        :args="args"
        :skipped="skipped"
        :no-save="noSave"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers} from "../../helpers/questionLoadHelpers";
import { personalDetails } from "../../options/personalDetails";

import QuestionBase from "../../QuestionBase";
import InputRadio from "../../inputs/InputRadio";

export default {
  name: 'Title',
  mixins: [personalDetails, questionLoadHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    noSave: {
      type: Boolean,
      default: false
    },
    objectId: {
      type: [
        Number,
        String
      ],
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'What is their title?',
        subTitle:  null,
        tip: null
      },
      db: {
        saveLocation: 'client_person',
        saveField: 'title',
        objectId: this.objectId
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
