export const lpaOptions = {
    data() {
        return {
            healthOptions: {
                lifeSustainingTreatment: [
                    {
                        label: 'Yes',
                        value: 'a'
                    },
                    {
                        label: 'No',
                        value: 'b'
                    }
                ]
            },
            propertyOptions: {
                authority: [
                    {
                        label: 'If Donor Incapacitated',
                        value: 'restricted'
                    },
                    {
                        label: 'Anytime With Consent',
                        value: 'registration'
                    }
                ]
            },
            workTogether: [
                {
                    label: 'Jointly',
                    value: 'jointly'
                },
                {
                    label: 'Joint and Severally',
                    value: 'joint_separate'
                },
                {
                    label: 'Jointly for Some Decisions / Severally for Others',
                    value: 'joint_separate_other'
                }
            ]
        }
    }
}
