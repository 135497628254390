<template>
  <QuestionnaireBase :form="form" :title="title" :finishFunction="null">
    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>
      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "../../loadHelpers";
import {dateFormat} from "@/mixins/dateFormat";

import QuestionnaireBase from "../../../../components/common/questionnaires/questionnaire";
import HealthAttorneys from "@/components/common/questionnaires/fieldsets/lpa/health/HealthAttorneys";
import HealthCertificateProvider
  from "@/components/common/questionnaires/fieldsets/lpa/health/HealthCertificateProvider";
import HealthOptions from "@/components/common/questionnaires/fieldsets/lpa/health/HealthOptions";

export default {
  name: "LpaSimpleHealth",
  mixins: [loadHelpers, dateFormat],
  components: {
    QuestionnaireBase,
    HealthAttorneys,
    HealthCertificateProvider,
    HealthOptions
  },
  data() {
    return {
      name: 'LpaHealthInstructions',
      title: 'LPA Health Instructions',
      url: 'estate_planning_lpa',
      order:
          [
            'HealthAttorneys',
            'HealthCertificateProvider',
            'HealthOptions'
          ],
      finishFunction: null,
      expiryAccessCodeOnFinish: true
    }
  }
};
</script>
