export const lpaRelationshipHelpers = {
    data () {
        return {
            placeholder: {
                type: 'person',
                title: null,
                name_first: 'TO',
                name_middle: 'BE',
                name_last: 'CONFIRMED',
                dob: null,
                relationship: 'with client',
                partner: false,
                gender: null,
                email: null,
                clients: [],
                placeholder: true
            }
        }
    },
    methods: {
        setRelationship(person, partner, clientId) {
            let disallowedRelationshipConverts = ['friend'];
            let partnerRelationship = null;
            if (partner) {
                partnerRelationship =
                    'relationship_to_client' in partner
                        ? partner.relationship_to_client
                        : 'relationship' in partner
                            ? partner.relationship
                            : null;
            }

            let personRelationship =
                'relationship_to_client' in person ? person.relationship_to_client : 'relationship' in person ? person.relationship : null;
            if (
                this.partnerListRelationships.includes(
                    personRelationship.split(' ')[0]
                )
            ) {
                return person.relationship.split(' ')[1];
            } else if (
                clientId &&
                !person.clients.includes(clientId) &&
                !person.partner &&
                partnerRelationship &&
                personRelationship &&
                !disallowedRelationshipConverts.includes(personRelationship)
            ) {
                return `${partnerRelationship}'s ${personRelationship}`;
            }
            return personRelationship;
        },
        convertPerson(person) {
            let result = {
                type: 'person',
                id: person.id,
                title: person.title,
                name_first: person.name_first,
                name_middle: person.name_middle,
                name_last: person.name_last,
                dob: person.dob,
                relationship: person.relationship_to_client,
                partner: person.partner,
                gender: person.gender,
                email: person.email,
                phone: person.phone,
                clients: person.clients
            }

            if (person.address && person.address.id) {
                result.address = {
                    id: person.address?.id,
                    line_1: person.address?.line_1,
                    line_2: person.address?.line_2,
                    post_town: person.address?.post_town,
                    postcode: person.address?.postcode,
                    county: person.address?.county,
                    country: person.address?.country
                }
            }

            return result
        }
    }
}
