<template>
  <b-modal
      v-model="showModal"
      :hide-header="true"
      modal-class="modal-style-one"
      scrollable
      size="xl"
      @hidden="clearSelectedPeople"
      @ok="addPeople"
      @shown="shown"
  >
    <div class="questionnaire">

      <QuestionBase
          :errors="[...jsErrors]"
          :question="question"
          :sub-section="true"
          :disabledValid="true"
          :valid="!!show.type"
      >
        <b-row>
          <b-col>
            <b-alert v-if="warningMessage" variant="secondary" show>{{ warningMessage}}</b-alert>
          </b-col>
          <b-col>
            <b-alert v-if="maximumAllowedSelection <= selectedPeople.length" variant="danger" show>
              {{ maximumAllowedWarningText}}
            </b-alert>
          </b-col>
        </b-row>

        <div
            v-show="!hideTypeOptions"
            class="radio-options-wide radio-options w-75"
        >
          <label
              v-for="(option, index) in typeOptions"
              :key="index"
              :class="{ active: option.value === show.type }"
              class="radio"
              @click="show.type = typeOptions[index].value"
          >
            <span>{{ option.label }}</span>
          </label>
        </div>
      </QuestionBase>

      <div class="pl-20 pr-36">
        <div
            v-if="show.type === 'person'"
            class="question-box"
            :class="{ 'with-arrow': !hideTypeOptions }"
        >
          <div v-if="!show.newPerson" class="accordion sub-items">

            <ObjectCard
                v-for="(person, index) in options"
                :id="'person' + person.id"
                :key="'person' + person.id + index"
                :ref="'person' + index"
                :select-mode="true"
                :selected="
                !!selectedPeople.find((option) => option.id === person.id)
              "
                :title="cardTitle(person)"
                :value="options[index]"
                :disabled="disabledPersonFunction ? disabledPersonFunction(person) : false"
                type="executorPrimary"
                :loading="loading"
                :index="index"
                :show-save="!options[index].placeholder"
                :show-delete="!options[index].placeholder"
                @click="selectPerson(person)"
                @delete="deletePerson(person.id)"
                @save="savePerson(null, 'person' + index)"
            >
              <PersonLpa
                  v-if="person.type === 'person'"
                  v-model="options[index]"
                  :no-save="false"
                  :objectId="person.id"
                  :certificate-provider="personType === 'certificate'"
              />
            </ObjectCard>
          </div>
          <b-button
              v-if="!show.newPerson"
              class="btn-question w-100"
              @click="show.newPerson = true"
          >
            <i class="i-Add text-25 font-weight-800 mr-2"> </i>New Person
          </b-button>

          <PersonLpa
              v-if="show.newPerson"
              v-model="formData.person"
              :errors-post="errorsPost"
              :noSave="true"
              :certificate-provider="personType === 'certificate'"
          />
        </div>
      </div>
    </div>
    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 m-0 d-flex">
        <button class="btn btn-backwards white-border" @click="cancel">
          Cancel
        </button>
        <button class="btn btn-forwards" @click="ok">Confirm</button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {personHelpers} from "@/mixins/personHelpers";
import {blankForms} from "@/components/common/questionnaires/question/helpers/blankForms";
import {peopleSaveHelpers} from "@/components/common/questionnaires/question/questions/objects/peopleSaveHelpers";
import {lpaOptions} from "@/components/common/questionnaires/question/options/lpaOptions";
import {lpaRelationshipHelpers} from "@/components/common/questionnaires/fieldsets/lpa/helpers/lpaRelationshipHelpers";

import ObjectCard from "@/components/common/questionnaires/question/questions/objects/ObjectCard";

import PersonLpa from "@/components/common/questionnaires/fieldsets/sub/people/PersonLpa";
import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";

export default {
  name: "LpaPeopleModal",
  mixins: [
    personHelpers,
    blankForms,
    peopleSaveHelpers,
    lpaRelationshipHelpers,
    lpaOptions,
  ],
  components: {
    QuestionBase,
    PersonLpa,
    ObjectCard,
  },
  mounted() {
    this.setForm();
  },
  props: {
    value: {
      // show modal
      type: Boolean,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    dataSelected: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: false,
    },
    hideTypeOptions: {
      type: Boolean,
      default: false,
    },
    noSave: {
      type: Boolean,
      default: false,
    },
    disabledPersonFunction: {
      type: Function,
      required: false
    },
    warningMessage: {
      type: String,
      required: false
    },
    personType: {
      type: String,
      required: false
    },
    maximumAllowedSelection: {
      // allowed number of people to be selected
      type: Number,
      default: 20
    },
    maximumAllowedWarningText: {
      type: String,
      default: 'Maximum number of people selected'
    }
  },
  computed: {
    showModal: {
      // show modal
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
    data: {
      // main selected people data
      set(value) {
        this.$emit("dataSelectedUpdate", value);
      },
      get() {
        return this.dataSelected;
      },
    },
    form: {
      // abstraction for person form to be compatible with peopleSaveHelpers.js
      set(value) {
        this.formData.person = value;
      },
      get() {
        return this.formData.person;
      },
    },
    typeOptions() {
      // type of allowed people options for radio buttons
      let options = [
        {
          label: "Person",
          value: "person",
        },
      ];
      return options;
    },
    question() {
      return {
        title: this.title,
        subTitle: this.subTitle,
        tip: null,
      };
    },
  },
  data() {
    return {
      // temporary selected people in modal
      db: {
        personSaveLocation: 'client_person_lpa'
      },
      show: {
        type: null,
        newPerson: false,
      },
      selectedPeople: [],
      formData: {
        person: null,
      }, // new person errors
      errorsPost: {},
      jsErrors: [],
    };
  },
  methods: {
    addPeople(bvModalEvt) {
      bvModalEvt.preventDefault();
      // tab 0 = existing people select
      // tab 1 = new person form
      if (this.show.type === "person" && !this.show.newPerson) {
        // if selected people
        for (let i in this.selectedPeople) {
          // if disable person function then skip adding person
          if (this.disabledPersonFunction && this.disabledPersonFunction(this.selectedPeople[i])) continue
          this.data.push(this.selectedPeople[i]);
        }
        this.clearSelectedPeopleAndClose();
        this.save();
      } else if (this.show.type === "person" && this.show.newPerson) {
        // if adding new person
        this.formData.person.person_type = this.personType
        this.savePerson(
            this.formData.person,
            null,
            this.clearSelectedPeopleAndClose,
            true,
            false,
            this.disabledPersonFunction
        );
      }
      this.$emit("added", this.data);
    },
    cardTitle(person) {
      return `${this.fullName(person)} (${person.relationship})`;
    },
    setForm() {
      this.formData.person = Object.assign({}, this.personBlankForm);
      this.formData.person.dependant = false;
      this.formData.person.clients.push()
    },
    selectPerson(value) {
      // add person to temporary selected people waiting for confirm
      // is person is already in temporary selected people
      let index = this.selectedPeople.findIndex(
          (person) => person.id === value.id
      );
      if (index >= 0) {
        // remove
        this.selectedPeople.splice(index, 1);
      } else {
        // guard
        // do not add if limit is reached
        if (this.maximumAllowedSelection >= 0 && this.selectedPeople.length >= this.maximumAllowedSelection) return
        // add to
        this.selectedPeople.push(value);
      }
    },
    clearSelectedPeople() {
      this.selectedPeople = [];
      this.show.newPerson = false;
      this.show.type = null;
      this.setForm();
    },
    clearSelectedPeopleAndClose() {
      this.clearSelectedPeople();
      this.showModal = false;
    },
    save() {
      if (this.noSave) return;
      this.$nextTick(() => {
        this.$emit("save");
      });
    },
    shown() {
      if (this.typeOptions.length === 1) this.show.type = "person";
    }
  },
};
</script>
