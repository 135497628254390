import {lpaPartnerHelpers} from "./lpaPartnerHelpers";
import {lpaRelationshipHelpers} from "./lpaRelationshipHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";

export const lpaAttorneyHelpers = {
    // required a details computed property: only to be used in root level Executors file
    mixins: [lpaPartnerHelpers, lpaRelationshipHelpers, saveHelpers],
    mounted() {
        // calculate people options
        this.setOptionsData()
        let attributeAdded = false
        // for old created lpa insert new fields into data
        if (!('primaryPartner' in this.details)) {
            // set new fields for old created lpas
            this.$set(this.details, 'primaryPartner', null)
            attributeAdded = true
        }
        if (!('backupYN' in this.details)) {
            // set new fields for old created lpas
            this.$set(this.details, 'backupYN', null)
            attributeAdded = true
        }
        if (attributeAdded) this.save()
    },
    watch: {
        storePeople: {
            immediate: false,
            deep: true,
            handler() {
                this.setOptionsData()
            }
        },
        details: {
            immediate: false,
            deep: true,
            handler() {
                this.setOptionsData()
            }
        },
        'details.primaryPartner': {
            immediate: false,
            deep: false,
            handler() {
                this.syncOptions()
            }
        },
        'details.backupYN': {
            immediate: false,
            deep: false,
            handler (value) {
                if (value === false) {
                    this.clearBackup()
                }
            }
        }
    },
    computed: {
        backupRequired() {
            return this.details.backupYN
        },
        storePeople() {
            return this.$store.getters.people
        }
    },
    methods: {
        async syncOptions() {
            this.clearAllAttorneys()
            await this.addPartner()
            this.save()
        },
        async addPartner() {
            // add partner to primary attorneys
            if (this.partner) {
                // find partner in primary attorneys
                let partnerPrimaryIndex = this.details.primary.findIndex(client => client.id === this.partner.id)
                if (partnerPrimaryIndex >= 0 && !this.details.primaryPartner) {
                    // remove partner
                    this.details.primary.splice(partnerPrimaryIndex, 1)
                    return true
                } else if (this.details.primaryPartner) {
                    // add partner
                    this.details.primary.push(this.convertPerson(this.partner))
                    // remove partner from  backup
                    let partnerBackupIndex = this.details.backup.findIndex(client => client.id === this.partner.id)
                    if (partnerBackupIndex >= 0) this.details.backup.splice(partnerBackupIndex, 1)
                    return true
                }
            } else {
                return false
            }
        },
        // async addProfessional() {
        //     // add professional executor depending on type of executors selected
        //     if (this.professionalsOnly || this.friendsAndProfessionals) await this.addProfessionalExecutor()
        //     return true
        // },
        // async addProfessionalExecutor() {
        //     this.details.primary.push(this.professionalDunham)
        //     return true
        // },
        clearAllAttorneys() {
            this.details.primary = []
            this.details.backup = []
        },
        clearBackup() {
            this.details.backup = []
            this.save()
        },
        setOptionsData() {
            // client_people from server with originally selected people filtered out,
            // mapped to be compatible with a Will then people relationships converted
            let serverPrimary = []
            let serverBackup = []

            // convert people
            let serverPeople = this.storePeople.map(person => {
                // do not convert professional as they have been added from TypeOfExecutors in final form for will
                // if (person.type === 'professional') return person
                // convert ClientPerson model into will person
                return this.convertPerson(person)
            })


            // filter out primary or backup options
            let primaryIds = this.details.primary.map(person => person.id)
            let backupIds = this.details.backup.map(person => person.id)
            serverPrimary = serverPeople.filter(person => !primaryIds.includes(person.id) && !backupIds.includes(person.id))
            serverBackup = serverPeople.filter(person => !primaryIds.includes(person.id) && !backupIds.includes(person.id))

            // return
            this.primaryOptions = [...serverPrimary, this.placeholder].sort((a, b) => a.id - b.id)
            this.backupOptions = [...serverBackup, this.placeholder].sort((a, b) => a.id - b.id)
        },
    }
}
