<template>
  <InputAddress
      v-model="data"
      :db-override="db"
      :question-override="question"
      :jsErrorsData="jsErrors"
      :noSave="noSave"
      :errors-post-data="errorsPost"
      @jsErrors="jsErrors=$event"
  />
</template>

<script>
import InputAddress from "../../inputs/InputAddress";

export default  {
  name: 'AddressPerson',
  components: {InputAddress},
  props: {
    value: {
      type: Object,
      required: false
    },
    form: {
      type: Object,
      required: true
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    objectId: {
      type: [Number, String],
      required: false
    },
    noSave: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title: 'What is their current address?'
      },
      db: {
        saveLocation: 'address_detail',
        saveField: 'address',
        deleteLocation: 'address_detail',
        objectId: this.objectId
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
      show: this.$parent.$parent.$data.show
    }
  }
}
</script>
