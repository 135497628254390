<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Certificate Provider <small>(Health Decisions)</small></h3>
    <div class="question-box with-arrow">

      <CertificateProviderHealth
          v-model="form.data.certificateProviders.health.details.primary"
          :attorneys="form.data.attorneys"
      />
    </div>
  </div>
</template>

<script>
import {fieldsetHelpers} from "../../fieldsetHelpers";
import CertificateProviderHealth
  from "@/components/common/questionnaires/question/questions/lpa/certificateProviders/health/CertificateProviderHealth";


export default {
  name: 'HealthCertificateProvider',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    CertificateProviderHealth,
  },
  data() {
    return {
      label: 'Certificate (Health)', // used for steps progress
      loadCondition: this.showFieldset, // used to conditionally load the fieldset uses
      // must be a function (this.form for arguments to function supplied)
      primaryOptions: []
    }
  },
  methods: {
    showFieldset() {
      return this.form.data.details.options.enabled.health
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
