<template>
  <QuestionBase
      :id="db.saveField"
      :errors="[...jsErrors]"
      :question="question"

      :valid="!!data.length"
  >
    <!-- Selected People -->
    <div class="accordion sub-items">
      <transition-group name="fade">
        <ObjectCard
            v-for="(person, index) in data"
            :id="'certificateProviderData' + index"
            :key="'certificateProviderData' + person.id + index"
            :ref="'certificateProviderData' + index"
            :select-mode="true"
            :index="index"
            :selected="true"
            :title="cardTitle(person)"
            :value="data[index]"
            :show-delete="false"
            :show-save="!person.placeholder"
            type="certificateProviderData"
            :loading="loading"
            @click="deselectConfirm(
                  person,
                  'Remove person the certificate provider role?'
                )
          "
            @delete="deletePerson(person.id)"
            @save="
            savePerson(
              null,
              'certificateProviderData' + index)"
        >
          <PersonLpa
              v-if="person.type === 'person'"
              v-model="data[index]"
              :key="'certificateProvider-form-data' + person.id"
              :errors-post="errorsPatch"
              :no-save="true"
              :objectId="person.id"
              :certificate-provider="true"
              @save="savePersonForm($event, {...person, person_type: 'certificate'})"
          />

        </ObjectCard>
      </transition-group>
    </div>

    <!-- New People -->
    <transition name="fade">
      <template
          v-if="data.length < maxAllowedPeople"
      >
        <b-button
            class="btn-question w-100"
            @click="show.addPerson = true"
        >
          <i class="i-Add text-25 font-weight-800 mr-2"> </i> Add Certificate Provider
        </b-button>
      </template>
    </transition>

    <LpaPeopleModal
        v-model="show.addPerson"
        :options="options"
        :dataSelected="data"
        :show-charities="false"
        :show-groups="false"
        :disabledPersonFunction="disableSelectPersonFunction"
        :show-dob="false"
        :maximum-allowed-selection="maxAllowedPeople - data.length"
        maximumAllowedWarningText="Maximum number of Certificate Providers selected"
        person-type="certificate"
        warningMessage="Person must be a friend to be a certificate provider and over 18 years old"
        title="Add Certificate Provider"
        :hide-type-options="true"
        @dataSelectedUpdate="data=$event"
        @save="save"
    />
  </QuestionBase>
</template>

<script>
import {personHelpers} from "@/mixins/personHelpers";
import {dateFormat} from "@/mixins/dateFormat";
import {peopleSaveHelpers} from "@/components/common/questionnaires/question/questions/objects/peopleSaveHelpers";
import {questionLoadHelpers} from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";
import {lpaRelationshipHelpers} from "@/components/common/questionnaires/fieldsets/lpa/helpers/lpaRelationshipHelpers";
import {lpaPeopleHelpers} from "@/components/common/questionnaires/question/questions/lpa/helpers/lpaPeopleHelpers";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import ObjectCard from "@/components/common/questionnaires/question/questions/objects/ObjectCard";

import PersonLpa from "@/components/common/questionnaires/fieldsets/sub/people/PersonLpa";
import LpaPeopleModal from "@/components/common/questionnaires/question/questions/lpa/helpers/LpaPeopleModal";

export default {
  name: "CertificateProviderHealth",
  components: {
    LpaPeopleModal,
    PersonLpa,
    ObjectCard,
    QuestionBase,
  },
  mixins: [
    peopleSaveHelpers,
    personHelpers,
    questionLoadHelpers,
    saveHelpers,
    lpaPeopleHelpers,
    lpaRelationshipHelpers,
    dateFormat
  ],
  props: {
    value: {
      type: Array,
      required: false,
    },
    attorneys: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      question: {
        title: 'Certificate Provider',
        subTitle: 'Select your certificate provider',
        tip: null,
      },
      db: {
        saveLocation: "product_lpa_data",
        saveField: "primaryCertificateProviderHealth",
        formPath: "data.certificateProviders.health.details.primary",
        jsonSaveField: "primary",
        personSaveLocation: 'client_person_lpa'
      },
      args: {
        skippable: false,
        required: true
      },
      serverErrors: [],
      errorsPatch: {},
      jsErrors: [],
      skipped: false,
      show: {
        addPerson: false,
      },
      maxAllowedPeople: 2
    };
  },
  computed: {
    data: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
    storePeople() {
      return this.$store.getters.people
    },
    options() {
      let attorneys = [...this.attorneys.health.details.primary,
        ...this.attorneys.health.details.backup].map(person => person.id)

      // must be over 18, not a partner, not an attorney or replacement attorney
      // needs more work to limit relationships
      return [...this.storePeople.map(person => {
        return this.convertPerson(person)
      }).filter(person => !this.isChild(person.dob) && !person.partner).filter(person => !attorneys.includes(person.id)), this.placeholder]
    }
  },
  methods: {
    disableSelectPersonFunction (person) {
      return (person.relationship !== 'friend' || this.isChild(person.dob)) && !person.placeholder
    }
  }
};
</script>
