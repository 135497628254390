<template>
  <QuestionBase
      :id="db.saveField"
      :errors="[...jsErrors]"
      :question="question"

      :valid="!!data.length"
  >
    <!-- Selected People -->
    <div class="accordion sub-items">
      <transition-group name="fade">
        <ObjectCard
            v-for="(person, index) in data"
            :id="'attorneyHealthBackupData' + index"
            :key="'attorneyHealthBackupData' + person.id + index"
            :ref="'attorneyHealthBackupData' + index"
            :select-mode="true"
            :index="index"
            :selected="true"
            :title="cardTitle(person)"
            :value="data[index]"
            :show-delete="false"
            :show-save="!person.placeholder"
            type="attorneyHealthBackupData"
            :loading="loading"
            @click="deselectConfirm(
                  person,
                  'Remove person from your replacement Attorneys?'
                )
          "
            @delete="deletePerson(person.id)"
            @save="
            savePerson(
              null,
              'attorneyHealthBackupData' + index)"
        >
          <PersonLpa
              v-if="person.type === 'person'"
              v-model="data[index]"
              :key="'attorneyHealthBackup-form-data' + person.id"
              :errors-post="errorsPatch"
              :no-save="true"
              :objectId="person.id"
              @save="savePersonForm($event, person)"
          />

        </ObjectCard>
      </transition-group>
    </div>

    <!-- New People -->
    <transition name="fade">
      <template
          v-if="data.length < maxAllowedPeople"
      >
        <b-button
            class="btn-question w-100"
            @click="show.addPerson = true"
        >
          <i class="i-Add text-25 font-weight-800 mr-2"> </i> Add Replacement Attorney
        </b-button>
      </template>
    </transition>

    <LpaPeopleModal
        v-model="show.addPerson"
        :options="optionsData"
        :dataSelected="data"
        :show-charities="false"
        :show-groups="false"
        title="Add Backup Attorney"
        :disabledPersonFunction="disableSelectPersonFunction"
        :maximum-allowed-selection="maxAllowedPeople - data.length"
        maximumAllowedWarningText="Maximum number of Replacement Attorneys selected"
        warningMessage="Person must be over 18 years old"
        @dataSelectedUpdate="data = $event"
        :sub-title="'Select attorneys by selecting existing people or adding new people. You can select multiple individuals for this role.'"
        :hide-type-options="true"
        @save="save"
    />
  </QuestionBase>
</template>

<script>
import {personHelpers} from "@/mixins/personHelpers";
import {peopleSaveHelpers} from "@/components/common/questionnaires/question/questions/objects/peopleSaveHelpers";
import {questionLoadHelpers} from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";
import {dateFormat} from "@/mixins/dateFormat";
import {lpaPeopleHelpers} from "@/components/common/questionnaires/question/questions/lpa/helpers/lpaPeopleHelpers";
import {lpaPartnerHelpers} from "@/components/common/questionnaires/fieldsets/lpa/helpers/lpaPartnerHelpers";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import ObjectCard from "@/components/common/questionnaires/question/questions/objects/ObjectCard";

import PersonLpa from "@/components/common/questionnaires/fieldsets/sub/people/PersonLpa";
import LpaPeopleModal from "@/components/common/questionnaires/question/questions/lpa/helpers/LpaPeopleModal";

export default {
  name: "BackupHealthAttorneys",
  components: {
    LpaPeopleModal,
    PersonLpa,
    ObjectCard,
    QuestionBase,
  },
  mixins: [
    dateFormat,
    peopleSaveHelpers,
    personHelpers,
    questionLoadHelpers,
    saveHelpers,
    lpaPeopleHelpers,
    lpaPartnerHelpers,
  ],
  props: {
    value: {
      type: Array,
      required: false,
    },
    details: {
      type: Object,
      required: true,
    },
    optionsData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      db: {
        saveLocation: "product_lpa_data",
        saveField: "backupAttorneysHealth",
        formPath: "data.attorneys.health.details.backup",
        jsonSaveField: "backup",
        personSaveLocation: 'client_person_lpa'
      },
      args: {
        skippable: false,
        required: true,
        customErrorMessage: "You must have more than one replacement attorney, click to select",
      },
      serverErrors: [],
      errorsPatch: {},
      jsErrors: [],
      skipped: false,
      show: {
        addPerson: false,
      },
      maxAllowedPeople: 4
    };
  },
  computed: {
    question() {
      return {
        title:
            this.data && this.data.length === 1
                ? "Backup Attorney"
                : "Backup Attorneys",
        subTitle: "Please confirm who you would like to act as the replacement attorneys. To deselect a person, simply click on their name.",
        tip: null,
      };
    },
    data: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    }
  },
  methods: {
    disableSelectPersonFunction(person) {
      return this.isChild(person.dob)
    }
  }
};
</script>
