<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Attorneys <small>(Health Decisions)</small></h3>

    <PartnerHealthPrimaryAttorneyYN
        v-if="partner"
        v-model="form.data.attorneys.health.details.primaryPartner"
    />

    <template v-if="([true, false].includes(details.primaryPartner) || !partner)">
      <div class="question-box with-arrow">
        <PrimaryHealthAttorneys
              v-model="form.data.attorneys.health.details.primary"
              :details="form.data.attorneys.health.details"
              :optionsData="primaryOptions"
        />
      </div>
    </template>

    <BackupHealthAttorneysYN
        v-if="form.data.attorneys.health.details.primary.length || form.data.attorneys.health.details.backupYN"
        v-model="form.data.attorneys.health.details.backupYN"
        :details="form.data.attorneys.health.details"/>

    <template v-if="form.data.attorneys.health.details.backupYN" >
      <div class="question-box with-arrow">
        <BackupHealthAttorneys
            v-model="form.data.attorneys.health.details.backup"
            :details="form.data.attorneys.health.details"
            :optionsData="backupOptions"
        />
      </div>
    </template>


  </div>
</template>

<script>
import {fieldsetHelpers} from "@/components/common/questionnaires/fieldsets/fieldsetHelpers";
import {lpaPartnerHelpers} from "@/components/common/questionnaires/fieldsets/lpa/helpers/lpaPartnerHelpers";
import {lpaAttorneyHelpers} from "@/components/common/questionnaires/fieldsets/lpa/helpers/lpaAttorneyHelpers";

import BackupHealthAttorneysYN
  from "@/components/common/questionnaires/question/questions/lpa/attorneys/health/BackupHealthAttorneysYN";
import BackupHealthAttorneys
  from "@/components/common/questionnaires/question/questions/lpa/attorneys/health/BackupHealthAttorneys";
import PrimaryHealthAttorneys
  from "@/components/common/questionnaires/question/questions/lpa/attorneys/health/PrimaryHealthAttorneys";
import PartnerHealthPrimaryAttorneyYN
  from "@/components/common/questionnaires/question/questions/lpa/attorneys/health/PartnerHealthPrimaryAttorneyYN";


export default {
  name: 'HealthAttorneys',
  mixins: [fieldsetHelpers, lpaPartnerHelpers, lpaAttorneyHelpers],
  props: {
    value: {
      type: Object,
      required: false
    }
  },
  components: {
    PartnerHealthPrimaryAttorneyYN,
    PrimaryHealthAttorneys,
    BackupHealthAttorneys,
    BackupHealthAttorneysYN
  },
  data() {
    return {
      label: 'Attorneys (Health)', // used for steps progress
      loadCondition: this.showFieldset, // used to conditionally load the fieldset uses
      // must be a function (this.form for arguments to function supplied)
      primaryOptions: [],
      db: {
        saveLocation: 'product_lpa_data',
        saveField: 'details',
        formPath: 'data.attorneys.health.details'
      },
    }
  },
  methods: {
    showFieldset() {
      return this.form.data.details.options.enabled.health
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    details() {
      // required for attorneys helpers file
      return this.form.data.attorneys.health.details
    },
    data() {
      return this.form.data.attorneys.health.details
    }
  }
};
</script>
