<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Options <small>(Health Decisions)</small></h3>

    <OptionHealthLifeTreatmentYN
        v-model="form.data.attorneys.health.details.generalOptions.health_life_sustaining_treatment" />

    <OptionHealthAttorneysPrimaryWorkTogetherYN v-if="form.data.attorneys.health.details.primary.length > 1"
                                                v-model="form.data.attorneys.health.details.primaryOptions.work_together" />
    <transition name="fade">
      <div v-if="form.data.attorneys.health.details.primaryOptions.work_together === 'joint_separate_other'"
           class="question-box with-arrow">
        <OptionHealthAttorneysPrimaryWorkTogetherOther v-model="form.data.attorneys.health.details.primaryOptions.work_together_other_details"

        />
      </div>
    </transition>


    <OptionHealthAttorneysBackupWorkTogetherYN v-if="form.data.attorneys.health.details.backup.length > 1"
                                               v-model="form.data.attorneys.health.details.backupOptions.work_together" />
    <transition name="fade">
      <div v-if="form.data.attorneys.health.details.backupOptions.work_together === 'joint_separate_other'"
           class="question-box with-arrow">
        <OptionsHealthAttorneysBackupWorkTogetherOther v-model="form.data.attorneys.health.details.backupOptions.work_together_other_details"

        />
      </div>
    </transition>


    <OptionHealthInstructionsYN v-model="form.data.attorneys.health.details.generalOptions.instructionsYN" />
    <transition name="fade">
      <div v-if="form.data.attorneys.health.details.generalOptions.instructionsYN"
           class="question-box with-arrow">
        <OptionsHealthInstructions v-model="form.data.attorneys.health.details.generalOptions.instructions" />
      </div>
    </transition>

    <OptionHealthPreferencesYN v-model="form.data.attorneys.health.details.generalOptions.preferencesYN" />
    <transition name="fade">
      <div v-if="form.data.attorneys.health.details.generalOptions.preferencesYN"
           class="question-box with-arrow">
        <OptionsHealthPreferences v-model="form.data.attorneys.health.details.generalOptions.preferences" />
      </div>
    </transition>

    <NotifiedPeopleHealthYN v-model="form.data.notifiedPeople.health.details.enabled" />

    <template v-if="form.data.notifiedPeople.health.details.enabled">
      <div class="question-box with-arrow">
        <NotifiedPeopleHealth
            v-model="form.data.notifiedPeople.health.details.primary"
        />
      </div>
    </template>


  </div>
</template>

<script>
import {fieldsetHelpers} from "../../fieldsetHelpers";
import OptionHealthLifeTreatmentYN from "@/components/common/questionnaires/question/questions/lpa/options/health/OptionHealthLifeTreatmentYN";
import OptionHealthInstructionsYN from "@/components/common/questionnaires/question/questions/lpa/options/health/OptionHealthInstructionsYN";
import OptionHealthPreferencesYN from "@/components/common/questionnaires/question/questions/lpa/options/health/OptionHealthPreferencesYN";
import OptionsHealthInstructions from "@/components/common/questionnaires/question/questions/lpa/options/health/OptionHealthInstructions";
import OptionsHealthPreferences from "@/components/common/questionnaires/question/questions/lpa/options/health/OptionHealthPreferences";
import OptionHealthAttorneysPrimaryWorkTogetherOther from "@/components/common/questionnaires/question/questions/lpa/options/health/OptionHealthAttorneysPrimaryWorkTogetherOther";
import OptionHealthAttorneysPrimaryWorkTogetherYN from "@/components/common/questionnaires/question/questions/lpa/options/health/OptionHealthAttorneysPrimaryWorkTogetherYN";
import OptionHealthAttorneysBackupWorkTogetherYN from "@/components/common/questionnaires/question/questions/lpa/options/health/OptionHealthAttorneysBackupWorkTogetherYN";
import OptionsHealthAttorneysBackupWorkTogetherOther from "@/components/common/questionnaires/question/questions/lpa/options/health/OptionHealthAttorneysBackupWorkTogetherOther";
import NotifiedPeopleHealthYN
  from "@/components/common/questionnaires/question/questions/lpa/notifiedPeople/health/NotifiedPeopleHealthYN";
import NotifiedPeopleHealth
  from "@/components/common/questionnaires/question/questions/lpa/notifiedPeople/health/NotifiedPeopleHealth";


export default {
  name: 'HealthOptions',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    NotifiedPeopleHealth,
    NotifiedPeopleHealthYN,
    OptionsHealthAttorneysBackupWorkTogetherOther,
    OptionHealthAttorneysBackupWorkTogetherYN,
    OptionHealthAttorneysPrimaryWorkTogetherYN,
    OptionHealthAttorneysPrimaryWorkTogetherOther,
    OptionsHealthPreferences,
    OptionsHealthInstructions,
    OptionHealthPreferencesYN,
    OptionHealthInstructionsYN,
    OptionHealthLifeTreatmentYN
  },
  data() {
    return {
      label: 'Options (Health)', // used for steps progress
      loadCondition: this.showFieldset, // used to conditionally load the fieldset uses
      // must be a function (this.form for arguments to function supplied)
    }
  },
  methods: {
    showFieldset() {
      return this.form.data.details.options.enabled.health
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
